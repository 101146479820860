@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@layer base{
    body{
        @apply font-[Open+Sans];
    }
    li{
        @apply px-4;
        @apply cursor-pointer
    }
}

.content-div{
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center;
    height: 250px;
}

.content-div:hover{
    background-image: linear-gradient(
        to right,
        rgba(112, 157, 255, 0.8),
        hsla(242, 74%, 61%, 0.8)
    )!important;
}